.legend {
  display: flex;
  justify-content: center;
  margin-top: 28px;
  font-variant: small-caps;

  ul {
    display: flex;
    justify-content: center;

    li {
      height: 26px;
      font-family: VolkartRegular;
      font-size: 13px;
      padding: 0 20px;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 13px;
      box-shadow: 0 1px 1px 0 #d1d1d1;
      margin-left: 12px;
      cursor: pointer;
      border: 1px solid #f6f6f6;

      &:first-of-type {
        margin-left: 0;
      }

      span {
        width: 11px;
        height: 11px;
        border-radius: 3px;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}
