.page-container {
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error {
  width: 100%;
  font-size: 10rem;
  line-height: 1.05;
  text-align: center;
  font-family: Volkart;
  color: #6e757a;
}

.title {
  width: 100%;
  text-align: center;
  font-family: Volkart;
  font-weight: 500;
}
