* {
  font-family: VolkartRegular, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


html {
  overflow:hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img, svg {
  vertical-align: middle;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("HelveticaNeue"), url("../assets/fonts/HelveticaNeue/HelveticaNeue.ttf") format("ttf");
}

@font-face {
  font-family: "BeatriceRegular";
  src: local("BeatriceRegular"), url("../assets/fonts/Beatrice/Beatrice-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "BeatriceSemibold";
  src: local("BeatriceSemibold"), url("../assets/fonts/Beatrice/Beatrice-Semibold.woff2") format("woff2");
}

@font-face {
  font-family: "VolkartMedium";
  src: url("../assets/fonts/Volkart/3AFCE6_0_0.eot");
  src: url("../assets/fonts/Volkart/3AFCE6_0_0.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/Volkart/3AFCE6_0_0.woff2") format("woff2"),
  url("../assets/fonts/Volkart/3AFCE6_0_0.woff") format("woff"),
  url("../assets/fonts/Volkart/3AFCE6_0_0.ttf") format("truetype"),
  url("../assets/fonts/Volkart/3AFCE6_0_0.svg#wf") format("svg");
}

@font-face {
  font-family: "VolkartRegular";
  src: url("../assets/fonts/Volkart/3AFCE6_1_0.eot");
  src: url("../assets/fonts/Volkart/3AFCE6_1_0.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/Volkart/3AFCE6_1_0.woff2") format("woff2"), url("../assets/fonts/Volkart/3AFCE6_1_0.woff") format("woff"),
  url("../assets/fonts/Volkart/3AFCE6_1_0.ttf") format("truetype"),
  url("../assets/fonts/Volkart/3AFCE6_1_0.svg#wf") format("svg");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
  background-color: currentColor;
}
